import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { Link } from "gatsby"
import ImgSuccess from "../../images/success.png"

export default function ActivatePage(props) {
  return (
    <Layout location={props.location}>
      <SEO title="Стрекоза"/>
      <section className="register-content">
        <div className="container">
          <>
            <div className="modal-content complete_center">
              <div className="modal-head">
                <h2>Активация прошла успешно!</h2>
              </div>
              <Link to="/">
                <img className="complete__img" src={ImgSuccess} alt="" />
              </Link>
              <p className="complete__head">Спасибо!</p>
              <p className="complete__text">
                На ваш телефон и e-mail были высланы данные для авторизации.
              </p>
            </div>
          </>
        </div>
      </section>
    </Layout>
  )
}

